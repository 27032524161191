import request from '@/utils/axios'

// 获取聊天记录
export function getService(data = {}) {
 let params = localStorage.getItem("query");
 if (params) {
  params = JSON.parse(params);
 } else {
  params = {}
 }
 data.metadata = { ...params }
 return request({
  url: '/support/chat',
  method: 'POST',
  data
 })
}
export function postOrderInfo(data = {}) {
 return request({
  url: '/dispute/ticket/evidence',
  method: 'POST',
  data
 })
}
export function getOrderInfo(data = {}) {
 return request({
  url: `/dispute/ticket/info/${data.orderCode}`,
  method: 'get',
  data
 })
}
//获取上传的 key
export function getSignedCode(params = {}) {
 return request({
  url: `/file/upload/pre/signed/url`,
  method: 'get',
  params,
  custom: {
   baseURL: 'https://api-business.vip-help.com',
  }
 })
}
export function putSignedCode(url, data = {}) {
 return request({
  url: url,
  method: 'put',
  headers: { 'Content-Type': 'multipart/form-data' },
  data
 })
}
//邮箱查询
export function supportEmail(data = {}) {
 let params = localStorage.getItem("query");
 if (params) {
  params = JSON.parse(params);
 } else {
  params = {}
 }
 data.metadata = { ...params }
 return request({
  url: '/support/email',
  method: 'POST',
  data
 })
}
//站点信息获取
export function supportInfo(data = {}) {
 let params = localStorage.getItem("query");
 if (params) {
  params = JSON.parse(params);
 } else {
  params = {}
 }
 data.metadata = { ...params }
 return request({
  url: '/support/info',
  method: 'POST',
  data
 })
}
//交易信息查询
export function transactionInfo(data = {}) {
 let params = localStorage.getItem("query");
 if (params) {
  params = JSON.parse(params);
 } else {
  params = {}
 }
 data.metadata = { ...params }
 return request({
  url: '/support/transaction/info',
  method: 'POST',
  data
 })
}
