import axios from "axios"
function changeSubdomainToApi(url, baseURL) {
	try {
		if (process.env.NODE_ENV !== 'production') {
			return process.env.VUE_APP_BASE_URL
		}
		// 创建一个新的URL对象
		const originalUrl = new URL(url);
		// 获取主机名（包括域名和子域名）
		const hostname = originalUrl.hostname;
		if (hostname === 'localhost') {
			return baseURL;
		}
		// 分割主机名为数组
		const parts = hostname.split('.');

		// 确定是否有子域名（至少三个部分，例如：www.example.com）
		if (parts.length > 2) {
			// 移除第一个部分（即子域名），并在前面添加'api'
			parts.shift();
			parts.unshift('api');
		} else {
			// 没有子域名，直接在前面加上'api.'
			parts.unshift('api');
		}

		// 将修改后的域名部分重新组合成字符串
		const newHostname = parts.join('.');

		// 替换原URL对象中的主机名
		originalUrl.hostname = newHostname;
		return originalUrl.href;
	} catch (error) {
		console.error("Error occurred while changing subdomain:", error);
		return null;
	}
}
const request = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL,
	withCredentials: false,
	timeout: 60000,
})

// 添加请求拦截器
request.interceptors.request.use(function (config) {
	if (localStorage.getItem('user_token_key')) {
		config.headers[localStorage.getItem('user_token_key')] = localStorage.getItem('user_token_value') || '';
	}
	// config.baseURL = changeSubdomainToApi(window.location.origin, config.baseURL)
	if (config.custom && config.custom.baseURL) {
		config.baseURL = config.custom.baseURL
	}
	if (config.custom && config.custom.headers) {
		Object.keys(config.custom.headers).forEach(key => {
			config.headers[key] = config.custom.headers[key]
		})
	}
	// 在发送请求之前做些什么
	return config;
}, function (error) {
	// 对请求错误做些什么
	return Promise.reject(error);
});

// 添加响应拦截器
request.interceptors.response.use(function (response) {
	// 对响应数据做点什么
	return response.data
}, function (error) {
	// 对响应错误做点什么
	return Promise.reject(error);
});

export default request
